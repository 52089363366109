
import {defineComponent, nextTick, onMounted, onUpdated, ref,watchEffect,watch} from "vue"
import dayjs from "dayjs"
import Api from '@/http/modules/index'
let { 
    'POST/invoice/find_share': findSpInvoiceApi,
    // 发票_上传发票附件
    'POST/invoice/upload_invoice': upload_invoiceApi,
    // 发票_下载发票附件
    'POST/invoice/down_invoice': down_invoiceApi,
    // 发票_预览发票附件
    'GET/invoice/preview': previewApi,

} = Api
export default defineComponent({
    name:"Invoice",
    props:{
        invoiceData: {
            type: Array,
            default: []
        },
        currentCoEstimateAmount: {
            type: Number,
            default: 0
        },
        invoiceLoading: {
            type: Boolean,
            default: false
        },
        invoiceTypeList: {
            type: Array,
            default: [
                { label: '普票', value: 'OR'},
                { label: '共用普票', value: 'SH_OR'},
                { label: '专票', value: 'SP'},
                { label: '共用专票', value: 'SH_SP'},
                { label: '无票', value: 'NO'},
                { label: '单据', value: 'BILL'},
                { label: '确认无票', value: 'CONFIRM_NO'},
            ]
        } as any
    },
    emits:["addInvoice","deleteInvoice","saveInvoice","invoiceTypeChange","totalAmountChange","invoiceNoEnterMethod","getInvoiceTotalAmount"],
    setup(props,{attrs,emit,slots}){
        watch(() => props.invoiceData,(newValue:any, oldValue:any) => {
            invoiceTotalAmount.value=invoiceAmountSum()
        },{deep:true})       
        const invoiceInfo = ref("")
        const isLoading = ref<boolean>(false)
        const invoiceTotalAmount=ref()
        const invoiceAmountSum=()=>{
            let sum=0
            // console.log(props.invoiceData)
            props.invoiceData.forEach((el:any)=>{
                sum+=Number(el.totalAmount)
            })
            return sum
        }
        const refInput =ref()
        
        const addInvoice=()=>{
            emit("addInvoice")
        }
        const invoiceType = (type: string) => {
            switch(type){
                case "OR":
                    return "普票"
                case "SP":
                    return "专票"
                case "SH_OR":
                    return "共用普票"
                case "SH_SP":
                    return "共用专票"
                case "NO":
                    return "无票"
                case "BILL":
                    return "单据"
                case "CONFIRM_NO":
                    return "确认无票"
                case "EI_OR":
                    return "电子发票-普票"
                case "EI_SP":
                    return "电子发票-专票"
                case "VoucherGovernment" :
                    return "收据"
                case "Invoice":
                    return "INVOICE"
                case "File":
                    return "文件"
                case "Intercourse":
                    return "往来"
            }
        }


        const deleteInvoice=(scope:any)=>{
            emit("deleteInvoice",scope)
        }
        const saveInvoice=(row:any)=>{
            emit("saveInvoice",row)
        }
        const invoiceTypeChange=(row:any)=>{
            emit("invoiceTypeChange",row)
        }
        const totalAmountChange=(row:any,e:any,key:string)=>{
            row[key]=(e.toString().replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'))-0
            emit("totalAmountChange",row)
            // invoiceTotalAmount.value=invoiceAmountSum()
        }
        const invoiceNoEnterMethod=()=>{
            emit("invoiceNoEnterMethod",invoiceInfo.value)
            invoiceInfo.value=""
        }
        const remoteMethod=(query:any,data:any)=>{
            if(!query)return
            let formdata: any = new FormData()
            formdata.append("invoiceNo",query)
            formdata.append("invoiceType",data.invoiceType)
            // findSpInvoiceApi({invoiceNo: query, invoiceType: data.invoiceType} as any, {contentType: 'formData'}).then((res:any)=>{
            findSpInvoiceApi(formdata).then((res:any)=>{
                data.spInvoiceList=res
            }).catch(()=>{
                data.spInvoiceList=[]
            })
        }
        const invoiceNoChange=(value:string,data:any)=>{
            let {spInvoiceList}=data
            if(!spInvoiceList||!spInvoiceList.length){
                data.spInvoiceList=[]
                return
            }  
            let idx= spInvoiceList.findIndex((el:any)=>el.invoiceNo==value)
            if(idx==-1) return
            data.invoiceCode=spInvoiceList[idx].invoiceCode
            data.invoiceDate=spInvoiceList[idx].invoiceDate
        }
       
        const addNoInvoice=()=>{
            emit("addInvoice",props.currentCoEstimateAmount-invoiceTotalAmount.value)
            // invoiceTotalAmount.value=invoiceAmountSum()
        }
        const  toMoney=(num:number|string)=>{
            if(!num){
                return 0
            }
            let money=null
            if((<string>num).length){
                money=parseFloat((<string>num)).toFixed(2)
            }else{
                money=(<number>num).toFixed(2)
            }             
            let moneyNum=parseFloat(money)
            return moneyNum.toLocaleString()
        }
        const tableRowClassName =({
                row,
                rowIndex,
                }: {
                row: any
                rowIndex: number
                }) => {
                if (row.isManual === 1) {
                    return 'manual-row'
                } 
                return ''
        }
        const handleInput=(e:any)=>{
            e.target.value =e.target.value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
        }
        watchEffect(()=>{
            emit("getInvoiceTotalAmount",invoiceTotalAmount.value)
        })
        const inputFocus=()=>{
            nextTick(()=>{
                refInput.value.focus()
            })
            
        }

        const upInvoiceConfig = ref([
            {
                label: '备注',
                value: '',
                upKey: 'note',
                props: { type: 'textarea' }
            }
        ])
        // 查看票据
        const previewHandle = (row: any) => {
            isLoading.value = true
            previewApi({id: row.id}).then((rep: any) => {
                window.open(rep.url)
                isLoading.value = false
            }).finally(() => {
                isLoading.value = false
            })
        }
        return{
            addInvoice,
            invoiceType,
            dayjs,
            deleteInvoice,
            saveInvoice,
            invoiceTypeChange,
            totalAmountChange,
            invoiceNoEnterMethod,
            invoiceInfo,
            invoiceNoChange,
            remoteMethod,
            invoiceTotalAmount,
            addNoInvoice,
            toMoney,
            tableRowClassName,
            handleInput,
            refInput,
            inputFocus,
            upload_invoiceApi,
            upInvoiceConfig,
            previewHandle,
            isLoading,
        }
    },
})
